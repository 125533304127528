@import "../../../public/mixins/mixins.scss";

.treatmentsWrapper {
  background: linear-gradient(180deg, #e3f8ff 0%, #fbfdfe 100%);
  padding: 50px 0px;
  @include mobile() {
    padding: 20px 0px;
  }
  .treatmentsContent {
    @include flexContainer(column, center, center);
    max-width: 1440px;
    padding: 0px 100px;
    @include mobile() {
      padding: 0px 20px;
    }
    margin: auto;
    .tagline {
      text-align: center;
      margin-bottom: 40px;
      @include font(32px, 700, #006daf, 1.28px);
      @include mobile() {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .greyTagline {
        @include font(32px, 200, #4a536a, 1.28px);
        @include mobile() {
          font-size: 20px;
        }
      }
    }

    .treatmentsContainer {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-gap: 15px;

      .treatment {
        text-align: center;
        padding: 25px 10px;
        height: 190px;
        border-radius: 20px;
        border: 1px solid #c3f1ff;
        background: #fff;
        box-shadow: 0px 1px 6px 2px #d2f4ff;
        @include mobile() {
          padding: 20px 15px;
        }
        .treatmentName {
          margin: auto;
          margin-top: 20px !important;
          text-align: center;
          @include font(14px, 600, #333333);
        }
      }
    }
    .viewMoreBtn {
      @include buttonType1(10px 45px, 20px);
    }
  }
}
